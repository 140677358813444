<template>
  <div class="inside-bg jobListPage">
    <div class="container" style="padding-top:100px">
      <b-alert class="row mt-2 alert-success" variant="success" :class="alert_class" style="width:89%;" :show="showDismissibleAlert" dismissible>{{success_msg}}</b-alert>
      <div class="row">
        <div class="col-lg-12">
          <nav area-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" :class="job_name == '' ? 'active' : ''" aria-current="page">
                <a v-if="job_name !== '' && $route.query.redirect_from == 'fresher_jobs'" href="/jobs-for-freshers">Jobs for freshers</a>
                <a v-else-if="job_name !== ''" href="/jobs">Jobs</a>
                <span v-else-if="$route.query.redirect_from == 'fresher_jobs'" class="pb-0">Jobs for freshers</span>
                <span v-else class="pb-0">Jobs</span>
              </li>
              <li class="breadcrumb-item active" v-if="job_name !== ''" aria-current="page">{{job_name}}</li>
            </ol>
          </nav>
          <div class="m-show mob-search-btn">
            <b-button v-b-toggle.collapse-1 variant="none"><v-icon>{{$icons.filterOutline}}</v-icon></b-button>
          </div>
          <div class="col content searchBox shadow p-4 m-hide">
            <div class="row">
              <div class="col-lg-3 mt-2">
                <input class="form-control fields" v-model="filter.search" name="keyword" type="text"  placeholder="Job title,Keywords, Company..." style="height:55px; !important">
              </div>
              <div class="col-lg-3 mt-2">
                <v-autocomplete v-model="filter.course_id" :items="courses" item-text="text" item-value="id" label="Select course" outlined></v-autocomplete>
              </div>
              <div class="col-lg-3 mt-2">
                <v-autocomplete v-model="filter.city_id" label="Select location" :items="locations" item-text="text" item-value="id" outlined clearable> 
                  <template slot="item" slot-scope="data">
                    <template>
                      <v-list-item-content>{{data.item.text}}</v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
              <div class="col-lg-3 mt-2">
                <v-autocomplete v-model="filter.job_role" :items="jobrole" item-text="text" item-value="value" label="Select role" outlined></v-autocomplete>
              </div>
              <div class="col-lg-3 form-group pb-0 text-center" style="margin-left:auto;margin-right:0;">
                <div class="row" style="justify-content:space-evenly">
                  <button class="btn btn-primary search-button" v-on:click="submitFilter"><v-icon class="text-white" size="20px">{{$icons.magnify}}</v-icon> Search</button>
                  <span style="color: #0054ad;cursor: pointer;" class="d-flex align-center" v-on:click="clearFilter"><u>Reset</u></span>
                </div>
              </div>
            </div>
          </div>
          <!-- mobile search bar -->
          <b-collapse id="collapse-1" >
            <div class="col content shadow m-searchBox p-4">
              <div class="row">
                <div class="col-lg-12">
                  <input class="form-control fields" v-model="filter.search" name="keyword" type="text"  placeholder="Keyword">
                </div>
                <div class="col-lg-12">
                  <v-autocomplete v-model="filter.course_id" :items="courses" item-text="text" item-value="id" label="Select course" outlined>
                  </v-autocomplete>
                </div>
                <div class="col-lg-12">
                  <v-autocomplete v-model="filter.city_id" label="Select location" :items="locations" item-text="text" item-value="id" outlined clearable> 
                    <template slot="item" slot-scope="data">
                      <template>
                        <v-list-item-content>{{data.item.text}}</v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
                <div class="col-lg-12 form-group pb-0 text-center">
                  <button class="btn btn-primary fields btn-block mb-2" style="line-height: 1.50" v-on:click="submitFilter"><v-icon>{{$icons.magnify}}</v-icon> Search</button><br>
                  <button  class="btn btn-outline-primary fields btn-block mt-2" v-on:click="clearFilter"><strong>Reset</strong></button >
                </div>
              </div>
            </div>
          </b-collapse>
          <!-- mobile search bar end -->
        </div>
      </div>
      <div class="row" v-if="top_content">
        <div class="col-md-12 top-content">
          <div class="col content shadow" v-if="!top_readMoreActivated" >
            <div v-html="top_content.slice(0,400)"></div><div class="" style="color: #0054ad;text-decoration: none;cursor: pointer;"  @click="activateReadMore('top')">Read more...</div>
          </div>
          <div class="col content shadow" v-if="top_readMoreActivated" v-html="top_content"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 order-md-2 m-hide">
          <div class="col content shadow">
            <div class="card filterBox">
              <article class="filter-group">
                <header class="card-header">
                  <p class="mb-0"><v-icon size="14px">{{$icons.tune}}</v-icon> Filters </p>
                </header> 
              </article>
              <article class="filter-group">
                <header class="card-header">
                  <span data-toggle="collapse" style="text-decoration:none !important;color: #0054ad;cursor: pointer;" data-target="#collapse_aside1" data-abc="true" aria-expanded="false" class="collapsed"> 
                    <v-icon class="text-dark icon-control mdi mt-0" size="14px"  @click="showJobrole = !showJobrole">{{ showJobrole ? $icons.minus : $icons.plus }}</v-icon>
                    <h6 class="text-dark">Job role</h6>
                  </span> 
                </header>
                <div class="filter-content collapse" :class="{'collapsed show': showJobrole}" id="role_filter" style="">
                  <div class="card-body filterInput pt-0">
                    <input type="text" id="input_role" name="role" value="" class="form-control" placeholder="Search" @input="filterRole" @click="f_jobrole=jobrole" >
                    <div v-if="f_jobrole.length" style="height:200px;overflow-y:scroll;">
                      <ul class="list-group pl-0">
                        <li class="list-group-item" style="cursor:pointer;" v-for="role in f_jobrole" :value="role.value" :key="role.value" @click="setRoleFilter">{{role.text}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
              <article class="filter-group">
                <header class="card-header">
                  <span data-toggle="collapse" style="text-decoration:none !important;color: #0054ad;cursor: pointer;" data-target="#collapse_aside5" data-abc="true" aria-expanded="false" class="collapsed"> 
                    <v-icon class="text-dark icon-control mdi mdi-plus mt-0" size="14px" @click="showExperience = !showExperience">{{ showExperience ? $icons.minus : $icons.plus }}</v-icon>
                    <h6 class="text-dark">Experience</h6>
                  </span> 
                </header>
                <div class="filter-content collapse" :class="{'collapsed show': showExperience}" id="experience_filter" style="">
                  <div class="card-body filterInput pt-0">
                    <input type="text" name="experience" class="form-control" placeholder="Search" @focus="f_exp=exp" @input="filterExp">
                    <div v-if="f_exp.length" style="height:200px;overflow-y:scroll;">
                      <ul class="list-group pl-0">
                        <li class="list-group-item" style="cursor:pointer;" v-for="exp in f_exp" :value="exp.value" :key="exp.value" @click="setExpFilter">{{exp.text}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
              <article class="filter-group">
                <header class="card-header"> 
                  <span data-toggle="collapse" style="text-decoration:none !important;color: #0054ad;cursor: pointer;" data-target="#collapse_aside2" data-abc="true" aria-expanded="false" class="collapsed">
                    <v-icon class="text-dark icon-control mdi mdi-plus mt-0" size="14px" @click="showSalary = !showSalary">{{ showSalary ? $icons.minus : $icons.plus }}</v-icon>
                    <h6 class="text-dark">Salary(Monthly) </h6>
                  </span>
                </header>
                <div class="filter-content collapse" :class="{'collapsed show': showSalary}" id="salary_filter" style="">
                  <div class="card-body filterInput pt-0">
                    <input type="text" name="salary" class="form-control" placeholder="Search" @blur="setSalaryFilter">
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="col-md-9 order-md-1 ">
          <div class="content myJobs shadow ">
            <div v-if="loading">
              <content-loader viewBox="0 0 476 124" primaryColor="#f3f3f3" secondaryColor="#cccccc" v-if="loading">
                <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              </content-loader>
            </div>
            <div v-else>
              <div class="jobsHeader">

                <div v-if="filterd"><span style="font-size:1.75rem !important;font-weight:500 !important;"><span v-if="filter.jobrole">{{selectedRole}}</span><span>Jobs </span><span v-if="filter.city_id">in {{selectedLocation}} </span></span></div>
                <h1 v-if="!filterd">{{job_head ? job_head : ($route.query.redirect_from == 'fresher_jobs' ? 'Fresher jobs' : 'Latest jobs')}}
                </h1>
                <h2 class="text-secondary" style="font-size: 1rem;" v-if="job_subhead">{{job_subhead}}</h2>

              </div>
              <div v-if="no_matching_jobs" class="col-md-12 px-0">
                <div class="container text-center " style="color:#7f7a7a;">
                  <p style="font-size:1.25rem !important;font-weight:500;"><strong>{{job_head}} vacancies not found at the moment.</strong></p>
                  <!-- <p style="font-size:1.25rem !important;font-weight:500;"><strong>There are no jobs now exactly matching your request.<br>Request to modify your search.</strong></p> -->
                  <p class="mb-0">Find the latest job opportunities below.</p>
                  <!-- <p v-if="!isLogin">For matching job alerts <a href="/jobseeker/register">Register now.</a></p> -->
                </div>
              </div>
              <div class="row" v-if="jobs.length">
                <div class="col-md-12 mb-2" v-for="(job) in jobs" :key="job.job_id">
                  <div class="card">
                    <div class="card-body jobBox">
                        <div class="row">
                          <div class="col-md-12">
                            <a class="mob-applay-btn m-show" :href="'/jobs/'+job.param"></a>
                            <h6 class="jobTitle">
                              <a class="text-decoration-none" :href="'/jobs/'+job.param">{{job.job_designation}}</a>
                              <span class="badge badge-primary float-right" v-if="job.prem_stat"><v-icon>{{ $icons.crown }}</v-icon> Premium</span>
                            </h6>
                              <p v-if="job.company_name">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/company.svg'" alt="company name" title="company name" width="18"></span>
                                <span>{{job.company_name}}</span>
                              </p>
                              <p v-if="job.courses" class="row"> 
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/collage.svg'" alt="education" title="education" width="23"></span><span v-for="(course , index) in job.courses" :key="index">{{course.name}}<span v-if="job.courses.length != index+1">,&nbsp;</span></span>
                              </p>
                              <p v-if="job.cities" class="row">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/location.svg'" alt="location" title="location" width="22"></span><span v-for="(city, index) in job.cities" :key="index">{{city}}<span v-if="job.cities.length != index+1">,&nbsp;</span></span>
                              </p>
                              <p  v-if="!job.salary_flag&&job.salary_range_from && job.salary_range_from > 0">
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/money.svg'" alt="salary" title="salary" width="21"></span>
                                <span><span v-if="job.salary_range_from">Rs. </span>{{job.salary_range_from}}<span v-if="job.salary_range_from&&job.salary_range_to"> - </span><span v-if="job.salary_range_to">Rs. </span>{{job.salary_range_to}} per month</span>
                              </p>
                              <p>
                                <span class="jobIcon"><img :src="$S3_url+'/Assets/images/calander.svg'" alt="last date to apply" title="Last date to apply" width="20"></span>
                                <span>Last date to apply: {{job.last_date}}</span> 
                              </p> 
                                <span v-if="job.share_times == 1" class="ml-4"><small class="text-secondary badge">shared by institute</small></span>
                            <div class="jobButton m-hide">
                                <a :href="'/jobs/'+job.param" class="btn btn-outline-primary mobright">View & Apply</a>
                              </div>
                          </div>
                        </div>
                      </div> 
                  </div> 
                </div>
              </div>
              <div v-if="!jobs.length && !no_matching_jobs" class="col-md-12">
                <div class="container text-center mt-5 mb-5" style="color:#afafaf;">
                  <p style="font-size:1.25rem !important;font-weight:500;"><strong>There are no jobs now exactly matching your request.<br>Request to modify your search.</strong></p>
                  <p>You can find the latest jobs published below.</p>
                  <p v-if="!isLogin">For matching job alerts <a href="/jobseeker/register">Register now.</a></p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="no_matching_jobs" class="col">
            <div class="loadMorebtn" style="font-size:1.25rem !important;"><a href="/jobs" target="_blank">Find more jobs</a></div>
          </div>
          <div class="col mt-4" v-if="jobs.length && jobs.length < meta.total && jobs.length !== meta.total">
            <div class="loadMorebtn blueTXT" @click="loadMore()">⟳ Load more</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="bottom_content">
        <div class="col-md-12 bottom-content">
          <div class="col content shadow" v-if="!bottom_readMoreActivated" >
            <div v-html="bottom_content.slice(0,3000)"></div><div class="" style="color: #0054ad;text-decoration: none;cursor: pointer;"  @click="activateReadMore('bottom')">Read more...</div>
          </div>
          <div class="col content shadow" v-if="bottom_readMoreActivated" v-html="bottom_content"></div>
        </div>
      </div>
      <div class="row " v-if="faqs.length">
        <div class="col-md-12">
          <div class="col relatedJobs shadow faq">
            <h5><strong>Read more about {{job_head}}</strong></h5>
            <span v-for="(faq,index) in faqs" :key="index">
              <div class="question expand" v-b-toggle="'collapse-' + index">{{faq.question}}</div>
              <b-collapse :id="'collapse-'+index" class="answer">
                <p>{{faq.answer}}</p>
              </b-collapse>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/hire.css'
import validation from '../libraries/validFormat' ;
import { mapState, mapGetters } from 'vuex';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
import store from '../store';
import Actions from '../libraries/apiActions.js';
import { ContentLoader } from 'vue-content-loader';
  import { BCollapse } from 'bootstrap-vue'


var breadcrumbJson = {
  type: "application/ld+json",
  json: {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Home",
    "item": window.location.origin
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "jobs",
    "item": window.location.href
  }]
  }
};
export default ({
  name: "JobList",
  components: {
    ContentLoader, BCollapse
  },
   metaInfo: (vue) => {
    const seo_title = vue.seo_title;
    const meta_desc = vue.meta_desc;
    const meta_keyword = vue.meta_keyword;
    const meta_loading = vue.meta_loading;
    const faqData = vue.faqs;
     return{
       title: meta_loading == true ? '' :(seo_title ? seo_title : 'Job search - Latest fresher govt and mnc job vacancies - Hireclap'),
       meta:[
        {name:'description',content: meta_desc ? meta_desc : 'Entry Level Jobs Search.Latest Jobs for Freshers In India. Government, IT software,bank , sales, MNC , work from home job vacancies'},
        {name:'keywords',content: meta_keyword ? meta_keyword : 'Jobs,Entry Level jobs, freshers opening, IT, Software, Core Technical ,Government , Defence , Research , Internship, BPO, MBA, Teaching, Pharma, StartUp, Diploma , BBM, BCA , BSc, Scholarships, Dream, Walkin , 1 to 3 Yr Exp jobs,jobs in Bangalore, pune, mumbai,Kolkata, Chennai, Delhi,  B.tech freshers job, freshers IT jobs, Btech,MBA,mechanical, electrical, engineering jobs'},
        {property: 'og:title', content: 'Job search - Latest fresher govt and mnc job vacancies - Hireclap'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url', content: window.location.href},
        {property: 'og:description', content: 'Entry Level Jobs Search.Latest Jobs for Freshers In India. Government, IT software,bank , sales, MNC , work from home job vacancies'},
        {property: 'og:image', content: 'https://d22r5huxahsjvt.cloudfront.net/Assets/images/hireclap-logo-HD.png'},
        {property: 'og:site_name', content: 'hireclap.com'},
       ],
       link: [
         { rel: 'canonical', href: process.env.VUE_APP_URL+window.location.pathname.replace('/',"")},
       ],
       script:  faqData && faqData.length > 0 ?[
          {
            type: "application/ld+json",
            json: {
              "@context": "http://schema.org/",
              "@type": "FAQPage",
              'mainEntity': faqData.map(item => ({
              '@type': 'Question',
              'name': item.question,
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': item.answer,
              },
            })),
            },
          },breadcrumbJson]:[breadcrumbJson],
     }
  },
  beforeMount() {
    console.log(window.location);
    this.isLogin = (this.$cookies.get("hire-seeker")&&this.$cookies.get("hire-seeker").token) ? true:false;
    if(this.isLogin){
      this.filter.user_id = this.$cookies.get("hire-seeker").id;
    }
    if (Object.keys(this.$route.params).length && this.$route.params.success) {
      this.showDismissibleAlert = true;
      this.success_msg = this.$route.params.success;
      this.alert_class = this.$route.params.status?'alert-success':'alert-error';
    } else {
      this.showDismissibleAlert = false;
    }
    if(this.$route.path != '/jobsearch' && this.$route.path != '/jobs' && this.$route.path != '/jobs/'){
      var url = this.$route.params.jobs_list;
        this.action.getJobsByURL(url).then(res => {
         if(res.url.length > 0){
          this.job_url = res.url[0];
          this.seo_title = this.job_url.seo_title;
          this.meta_desc = this.job_url.meta_description;
          this.meta_keyword = this.job_url.meta_keyword;
          this.meta_loading = false;
          this.job_head = this.job_url.page_head;
          this.job_subhead = this.job_url.page_subhead;
          this.job_name = this.job_url.name;
          this.top_content = this.job_url.top_content;
          this.bottom_content = this.job_url.bottom_content;
          this.faqs= this.job_url.faq;
          this.filter.city_id = (this.job_url.static_id == 1)?this.job_url.city_id:null;
          this.filter.category_id = (this.job_url.static_id == 4)?this.job_url.category_id:null;
          this.filter.course_id = (this.job_url.static_id == 2)?this.job_url.course_id:null;
          this.filter.job_role = (this.job_url.static_id == 3)?this.job_url.role_id:null;
          this.filter.search = (this.job_url.static_id == 5)?this.job_url.api_url_parameter.split('=')[1]:null;
          this.action.listJobsByUrl(this.job_url.api_url_parameter).then((res) => {
            this.jobs = res.jobs;
            this.meta = res.meta;
            this.limit = res.meta.limit;
            this.filter.offset = res.meta.offset;
            this.length = Math.ceil(res.meta.total/res.meta.limit);
            if(this.jobs.length == 0) {
              this.no_matching_jobs = true;
              this.action.listJobsByUrl('/job_listing?random=true').then((res) => {
              this.jobs = res.jobs;
              this.jobs.forEach(job => {
                job.last_date = this.validate.convertTimestamp(job.last_date);
                let param = job.job_url?job.job_url:'';
                param = param.replace(/[^A-Z0-9]+/ig, "-");
                if(param.length > 80){
                  let param_split = param.split("-");
                  let id_length = param_split[param_split.length-1].length;
                  param = param.substring(0, 80-(id_length+1));
                  param = param+'-'+param_split[param_split.length-1];
                }
                job.param = param;
              });
              this.loading = false;
            });
            } else {
              this.no_matching_jobs = false;
              this.jobs.forEach(job => {
                job.last_date = this.validate.convertTimestamp(job.last_date);
                let param = job.job_url?job.job_url:'';
                param = param.replace(/[^A-Z0-9]+/ig, "-");
                if(param.length > 80){
                  let param_split = param.split("-");
                  let id_length = param_split[param_split.length-1].length;
                  param = param.substring(0, 80-(id_length+1));
                  param = param+'-'+param_split[param_split.length-1];
                }
                job.param = param;
              });
            }
            this.loading = false;
          });
          }else{
            this.$router.push('/not-found');
          }
        });
    }else{
      this.meta_loading = false;
      this.filter.experience_from = (this.$route.query.redirect_from == 'fresher_jobs')? this.$route.query.experience_from :null;
      this.filter.experience_to = (this.$route.query.redirect_from == 'fresher_jobs')? this.$route.query.experience_to :null;
      this.filter.city_id = (this.$route.query.city_id)? JSON.parse(this.$route.query.city_id):null;
      this.filter.course_id = (this.$route.query.course_id)? JSON.parse(this.$route.query.course_id):null;
      this.filter.job_role = (this.$route.query.role_id)? JSON.parse(this.$route.query.role_id):null;
      this.filter.search = (this.$route.query.search)? this.$route.query.search:null;
      this.getJobList();
    }
  },
  beforeCreate() {
      this.$store = store;
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    })
  },
  data() {
    return {
      validate: new validation(),
      action: new Actions(),
      jobs:[],
      seo_title: '',
      meta_desc: '',
      meta_keyword: '',
      job_head: '',
      job_subhead:null,
      job_name: '',
      loading:true,
      meta_loading:true,
      f_jobrole:[],
      f_exp:[],
      f_qualification:[],
      f_locations:[],
      no_matching_jobs:false,
      meta:null,
      exp:[
        { value: 1, text: "0 Month(Fresher)" },
        { value: 6, text: "06 Months" },
        { value: 12, text: "1 Year" },
        { value: 24, text: "2 year" },
        { value: 36, text: "3 year" },
        { value: 48, text: "4 year" },
        { value: 60, text: "5 year" },
        { value: 72, text: "6 year" },
        { value: 84, text: "6+ year" },
      ],
      pages:[],
      page:1,
      length: 0,
      perPage:25,
      filter:{
        job_role:null,
        city_id:null,
        experience_from:null,
        experience_to:null,
        salary_range_from:null,
        course_id:null,
        search:null,
        offset:0,
      },
      selectedRole:null,
      selectedLocation:null,
      filterd:false,
      limit: 25,
      showJobrole:false,
      showExperience:false,
      showSalary:false,
      job_url:null,
      top_content:null,
      bottom_content:null,
      showDismissibleAlert:false,
      success_msg:null,
      alert_class:null,
      isLogin:false,
      top_readMoreActivated:false,
      bottom_readMoreActivated:false,
      faqs:[],
      faq_metaInfo:[]
    }
  },
  methods:{
    setHeader(){
      this.filterd = (this.filter.jobrole||this.filter.city_id)?true:false;
      this.jobrole.forEach(ele => {
        if(this.filter.jobrole && ele.value == this.filter.jobrole){
          this.selectedRole = ele.text
        }
      })
      this.locations.forEach(loc => {
        if(loc.id && loc.id == this.filter.city_id){
          this.selectedLocation = loc.text
        }
      })
    },
    activateReadMore(type){
      if(type == 'top'){
        this.top_readMoreActivated = true;
      }else{
        this.bottom_readMoreActivated = true;
      }
    },
    loadMore() {
      this.filter.offset += this.limit;
      this.action.getJobList(this.filter).then((res) => {
        res.jobs.forEach(job => {
          job.last_date = this.validate.convertTimestamp(job.last_date);
          let param = job.job_url?job.job_url:'';
          param = param.replace(/[^A-Z0-9]+/ig, "-");
          if(param.length > 80){
            let param_split = param.split("-");
            let id_length = param_split[param_split.length-1].length;
            param = param.substring(0, 80-(id_length+1));
            param = param+'-'+param_split[param_split.length-1];
          }
          job.param = param;
        })
        this.jobs = this.jobs.concat(res.jobs);
        this.limit = res.meta.limit;
        this.filter.offset = res.meta.offset;
      })

    },
    getJobList(){
      this.pages=[];
      this.perPage=25;
      this.action.getJobList(this.filter).then((res) => {
        this.jobs = res.jobs;
        this.meta = res.meta;
        
        this.limit = res.meta.limit;
        this.filter.offset = res.meta.offset;
        this.length = Math.ceil(res.meta.total/res.meta.limit);

        this.jobs.forEach(job => {
          job.last_date = this.validate.convertTimestamp(job.last_date);
          let param = job.job_url?job.job_url:'';
          param = param.replace(/[^A-Z0-9]+/ig, "-");
          if(param.length > 80){
            let param_split = param.split("-");
            let id_length = param_split[param_split.length-1].length;
            param = param.substring(0, 80-(id_length+1));
            param = param+'-'+param_split[param_split.length-1];
          }
          job.param = param;
        })
        this.loading = false;
      })
    },
    filterRole(e){
      if(e.target.value!=''){
        this.jobrole.forEach(role => {
          if(role.text.match(e.target.value)){
            this.f_.push(role);
          }else{
            this.f_jobrole=[];
          }
        });
      }else{
        this.f_jobrole = this.jobrole;
      }
    },
    async copyToClipboard(job, index){
      let param = job.job_url?job.job_url:'';
      param = param.replace(/[^A-Z0-9]+/ig, "-");
      if(param.length > 80){
        let param_split = param.split("-");
        let id_length = param_split[param_split.length-1].length;
        param = param.substring(0, 80-(id_length+1));
        param = param+'-'+param_split[param_split.length-1];
      }
      await navigator.clipboard.writeText(process.env.VUE_APP_URL_WS_HTTP+'/jobs/'+param);
      document.getElementById('copy-'+index).style.display = "inline";
      setTimeout(() =>{
        document.getElementById('copy-'+index).style.display = "none";
      },3000)
    },
    filterExp(e){
      if(e.target.value!=''){
        this.exp.forEach(ex => {
          if(ex.text.match(e.target.value)){
            this.f_exp.push(ex);
          }else{
            this.f_exp=[];
          }
        });
      }else{
        this.f_exp = this.exp;
      }
    },
    setRoleFilter(e){
      e.preventDefault();
      this.filter.job_role = e.target.value;
      this.getJobList();
      e.target.parentNode.parentNode.previousSibling.value = e.target.textContent;
      this.f_jobrole = [];
    },
    setExpFilter(e){
      this.filter.experience_from = e.target.value;
      this.getJobList();
      e.target.parentNode.parentNode.previousSibling.value = e.target.textContent;
      this.f_exp = [];
    },
    setSalaryFilter(e){
      this.filter.salary_range_from = e.target.value;
      this.getJobList();
      e.target.parentNode.parentNode.previousSibling.value = e.target.textContent;
    },
    submitFilter(e){
      e.preventDefault();
      if(this.$route.path == '/jobs'){
        var search_query = {};
        if(this.filter.search){
          search_query.search = this.filter.search
        }
        if(this.filter.course_id){
          search_query.course_id = this.filter.course_id
        }
        if(this.filter.city_id){
          search_query.city_id = this.filter.city_id
        }
        if(this.filter.job_role){
          search_query.role_id = this.filter.job_role
        }
        this.$router.push({ path: '/jobsearch', query: search_query });
        this.getJobList();
        this.setHeader();
      }
      else{
        this.filter.offset = 0;
        var search_param = {};
        if(this.$route.query.redirect_from == 'fresher_jobs') {
          search_param.redirect_from = 'fresher_jobs';
        }
        if(this.filter.experience_from){
          search_param.experience_from = this.filter.experience_from
        }
        if(this.filter.experience_to){
          search_param.experience_to = this.filter.experience_to
        }
        if(this.filter.search){
          search_param.search = this.filter.search
        }
        if(this.filter.course_id){
          search_param.course_id = this.filter.course_id
        }
        if(this.filter.city_id){
          search_param.city_id = this.filter.city_id
        }
        if(this.filter.job_role){
          search_param.role_id = this.filter.job_role
        }
        this.getJobList();
        this.$router.replace({ path: '/jobsearch', query: search_param });
        this.setHeader();
      }
    },
    clearFilter(e){
      e.preventDefault();
      this.filter.search = null;
      this.filter.course_id = null;
      this.filter.city_id = null;
      this.filter.job_role = null;
      this.resetFilter();
    },
    resetFilter(){
      if(this.$route.path == '/jobs'){
        this.$router.push('/jobs')
      }else{
        if(this.$route.query.redirect_from == 'fresher_jobs') {
          this.$router.push({ path: '/jobsearch', query: {'experience_from': 1,'experience_to': 12, 'redirect_from': 'fresher_jobs'} });
        } else this.$router.push('/jobsearch')
      }
      location.reload();
    },
    share(job){
      let param = (job.job_designation)? job.job_designation:'';
      param += job.cities?(job.job_designation)?('-'+job.cities[0]):job.cities[0]:'';
      param += job.company_name? (job.cities ||job.job_designation) ?'-'+job.company_name:job.company_name:'';
      param += job.job_id?(job.cities ||job.job_designation||job.company_name)?'-'+job.job_id:job.job_id:'';

      param = param.replace(/[^A-Z0-9]+/ig, "-");
      var currentUrl = this.$route.currentUrl;
      console.log('http://www.facebook.com/share.php?u=' + currentUrl+'/'+param);
        // document.getElementById('fb_share').href ='http://www.facebook.com/share.php?u=' + encodeURIComponent(location.href)+'/'+param; 
      // this.$router.replace('https://www.facebook.com/share.php?u='+currentUrl+'/'+param);
    }
  }
})
</script>
<style>
.v-application ol,
.v-application ul {
  padding-left: 24px !important;
}
</style>